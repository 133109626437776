<script>
  import Layout from "./Default.svelte";

  import { fade } from "svelte/transition"
  import { page } from "@inertiajs/svelte";
  import classNames from "classnames";

  import {
    IconCalendar,
    IconCheckSquareEdgeRounded,
    IconClose,
    IconCopy,
    IconDollar,
    IconGear,
    IconGoogle,
    IconLink,
    IconLogin,
    IconLogout,
    IconMicrosoft,
    IconNotepad, IconPenBook, IconRevert
  } from "@/Components/Assets/Icons";
  import Icon from "@/Components/Assets/Icon.svelte";
  import Logo from "@/Components/Assets/Logo.svelte";
  import MenuItem from "@/Components/Layout/MenuItem.svelte";
  import Wallet from "@/Components/Elements/Wallet.svelte";
  import { closeSideMenu, isSideMenuCollapsed, openSideMenu } from "@/Lib/stores/menus";
  import copy from "@/Lib/utils/copy";
  import posthog from "posthog-js";
  import {onMount} from "svelte";
  import * as Sentry from "@sentry/browser";

  const changeUrl = () => {
    closeSideMenu()
  }

  let campaignLinksEnabled = false;

  onMount(async () => {
    if (posthog?.onFeatureFlags && posthog?.__loaded) {
      posthog.onFeatureFlags(() => {
        if (posthog.isFeatureEnabled('CampaignLinks')) {
          campaignLinksEnabled = true;
        }
      });
    }

    window.hsConversationsSettings = {
      avoidInlineStyles: true
    };

    if ($page.props.auth?.user?.id) {
      Sentry.setUser({
        id: $page.props.auth?.user?.id,
        email: $page.props.auth?.user?.email
      });

      const _hsq = window._hsq = window._hsq || [];
      _hsq.push(["identify",{
        email: $page.props.auth?.user?.email,
        firstname: $page.props.auth?.user?.first_name,
        lastname: $page.props.auth?.user?.last_name,
        id: $page.props.auth?.user?.id
      }]);
    }
  });

  $: sideMenu = [
    {
      name: 'Buyers',
      items: [
        { name: 'Respond', url: '/app/respond', icon: IconCheckSquareEdgeRounded, enabled: true },
        { name: 'Manage your Rates', url: '/app/manage-rates', icon: IconGear, enabled: true },
      ]
    },
    {
      name: 'Sellers',
      items: [
        { name: 'Responses', url: '/app/responses', icon: IconNotepad, enabled: true },
        { name: 'Buyer Marketplace', url: '/app/marketplace', icon: IconDollar, enabled: true },
        { name: 'Campaign Links', url: '/app/campaign-links', icon: IconLink, enabled: campaignLinksEnabled },
      ]
    },
    {
      name: 'Account',
      items: [
        { name: 'Update your Information', url: '/app/profile', icon: IconPenBook, enabled: true },
        { name: 'Settings', url: '/app/settings', icon: IconGear, enabled: true },
      ]
    },
  ];

  const full_url = $page.props.common.app_url + $page.url,
   activeMenu = (uri) => full_url === uri || $page.url === uri || $page.url?.startsWith(uri) || full_url?.startsWith(uri)
</script>

<svelte:head>
  <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/22351541.js"></script>
</svelte:head>

<Layout>
  <main>
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <div class={classNames('off-canvas', $isSideMenuCollapsed ? '' : 'open')} role="dialog" aria-modal="true">

      <!-- Off-canvas menu backdrop -->
      <div
        in:fade
        out:fade
        class="backdrop"
        role="presentation"
        on:keydown
        on:keypress
        on:keyup
        on:click={() => closeSideMenu()}
      ></div>

      <!-- Off-canvas menu, show/hide based on off-canvas menu state. -->
      <div class="menu">
        <div>
          <!-- Close button, show/hide based on off-canvas menu state. -->
          <div class="close" in:fade out:fade>
            <button type="button" on:click|preventDefault={() => closeSideMenu()}>
              <span class="sr-only">Close sidebar</span>
              <Icon data={IconClose} class="fill-black" size="xl" />
            </button>
          </div>

          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div class="links">
            <nav>
              <ul>
                {#each sideMenu as {name, items}}
                  <li>
                    <h4>{name}</h4>
                    <ul>
                      {#each items as item}
                        {#if item.enabled === true}
                          <li>
                            <MenuItem
                              active={activeMenu(item.url)}
                              enabled={item.enabled}
                              title={item.name}
                              href={item.url}
                              notices={item.notices}
                              icon={item.icon}
                              count={item?.count}
                              on:click={() => changeUrl()}
                            />
                          </li>
                        {/if}
                      {/each}
                    </ul>
                  </li>
                {/each}

                {#if $page.props.auth.login_provider === 'google'}
                  <li>
                    <a href="https://workspace.google.com/marketplace/app/request_for_meeting/126639686392" rel="noreferrer noopener" target="_blank">
                      <Icon data={IconGoogle} />
                      Install the Gmail Plugin
                    </a>
                  </li>
                {:else if $page.props.auth.login_provider === 'microsoft'}
                  <li>
                    <a href="https://appsource.microsoft.com/en-GB/product/office/WA200006327" rel="noreferrer noopener" target="_blank">
                      <Icon data={IconMicrosoft} />
                      Install the Microsoft Add-In
                    </a>
                  </li>
                {/if}

                <li>
                  <button type="button" on:click={copy(`${$page.props.common.app_url}/@${$page.props.auth?.user?.slug}`)}>
                    <Icon data={IconCopy} />
                    Copy Your Personal Link
                  </button>
                </li>

                <li>
                  <a href="/logout">
                    <Icon data={IconLogout} />
                    Log out
                  </a>
                </li>

                {#if $page.props.auth.user?.role === 'Admin'}
                  <li>
                    <h4>Admin</h4>
                    <ul>
                      {#if $page.props?.auth?.masquerading}
                        <li>
                          <a href="/revert-masquerade">
                            <Icon data={IconLogin} />
                            Revert back
                          </a>
                        </li>
                      {/if}

                      <li>
                        <a href="/app/events">
                          <Icon data={IconCalendar} />
                          Events
                        </a>
                      </li>
                    </ul>
                  </li>
                {/if}
              </ul>
            </nav>

            <Wallet total={$page.props?.auth?.wallet?.display_total} />
          </div>
        </div>
      </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="sidebar">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div>
        <div class="header">
          <Logo theme="light" type="text" href="/app/respond" outerClass="block h-auto w-full max-w-[160px]" />
        </div>

        <nav>
          <ul>
            {#each sideMenu as {name, items}}
              <li>
                <h4>{name}</h4>
                <ul>
                  {#each items as item}
                    {#if item.enabled === true}
                      <li>
                        <MenuItem
                          active={activeMenu(item.url)}
                          title={item.name}
                          href={item.url}
                          notices={item.notices}
                          icon={item.icon}
                          count={item?.count}
                          on:click={() => changeUrl()}
                        />
                      </li>
                    {/if}
                  {/each}
                </ul>
              </li>
            {/each}

            {#if $page.props.auth.login_provider === 'google'}
              <li>
                <a class="plugin" href="https://workspace.google.com/marketplace/app/request_for_meeting/126639686392" rel="noreferrer noopener" target="_blank">
                  <svg role="img" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <title>Google</title>
                    <path d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z" />
                  </svg>
                  Install the Gmail Plugin
                </a>
              </li>
            {:else if $page.props.auth.login_provider === 'microsoft'}
              <li>
                <a class="plugin" href="https://appsource.microsoft.com/en-GB/product/office/WA200006327" rel="noreferrer noopener" target="_blank">
                  <svg role="img" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <title>Microsoft</title>
                    <path d="M0 0v11.408h11.408V0zm12.594 0v11.408H24V0zM0 12.594V24h11.408V12.594zm12.594 0V24H24V12.594z" />
                  </svg>
                  Install the Microsoft Add-In
                </a>
              </li>
            {/if}

            <li>
              <button class="copy" type="button" on:click={copy(`${$page.props.common.app_url}/@${$page.props.auth?.user?.slug}`)}>
                <svg role="img" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                  <path d="M15.83.5H5.17c-.18 0-.35.07-.47.2-.12.12-.2.29-.2.47V4.5H1.17c-.18 0-.35.07-.47.2a.65.65 0 0 0-.2.47v10.67c0 .18.07.35.2.47.12.13.29.2.47.2h10.67c.18 0 .35-.07.47-.2.13-.12.2-.29.2-.47v-3.33h3.33c.18 0 .35-.07.47-.2.13-.12.2-.29.2-.47V1.17c0-.18-.07-.35-.2-.47a.634.634 0 0 0-.47-.2Zm-10 4V1.83h9.33v9.33h-2.67v-6c0-.18-.07-.35-.2-.47a.634.634 0 0 0-.47-.2h-6Zm-4 10.67V5.83h9.33v9.33H1.83Z" />
                </svg>
                Copy Your Personal Link
              </button>
            </li>

            <li>
              <a class="logout" href="/logout">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" focusable="false">
                  <path fill-rule="evenodd" d="M7.112 2.281c-.376.045-.69.142-.992.307-.666.362-1.201.964-1.434 1.612-.194.542-.186.207-.186 7.8s-.008 7.258.186 7.8c.309.859 1.097 1.582 2.016 1.848l.318.092h6.96l.318-.092a3.054 3.054 0 0 0 2.087-2.068l.109-.36.017-1.98c.009-1.089.004-2.11-.011-2.269-.047-.507-.262-.705-.763-.702-.23.001-.323.021-.436.09-.289.179-.279.089-.301 2.541l-.02 2.2-.123.26c-.152.32-.443.619-.737.757l-.22.103H7.1l-.22-.103c-.294-.138-.585-.437-.737-.757l-.123-.26V4.9l.123-.26a1.63 1.63 0 0 1 .727-.747l.23-.113h6.8l.237.12c.314.159.569.421.72.74l.123.26.02 2.2c.019 2.122.023 2.205.101 2.339.127.215.309.301.639.301.49 0 .713-.209.76-.711.015-.159.02-1.18.011-2.269l-.017-1.98-.109-.36a3.587 3.587 0 0 0-.233-.58c-.401-.719-1.097-1.276-1.855-1.488-.314-.088-.343-.089-3.617-.096-1.815-.004-3.42.007-3.568.025m4.648 5.983c-.085.037-.74.655-1.682 1.588-.848.84-1.587 1.567-1.642 1.615a.857.857 0 0 0-.177.31c-.074.219-.074.227.001.449a.873.873 0 0 0 .195.33c.064.057.812.791 1.661 1.63 1.7 1.68 1.701 1.681 2.049 1.577.218-.065.533-.38.598-.598.099-.331.051-.402-.995-1.454l-.943-.95 5.597-.01c5.516-.011 5.6-.012 5.734-.091a.63.63 0 0 0 .324-.478c.059-.391-.053-.682-.324-.842-.134-.079-.218-.08-5.733-.091l-5.598-.011.943-.949c1.037-1.043 1.094-1.125.999-1.441-.091-.305-.503-.651-.77-.646a.744.744 0 0 0-.237.062" />
                </svg>
                Log out
              </a>
            </li>

            {#if $page.props.auth.user?.role === 'Admin' || $page.props?.auth?.masquerading}
              <li class="admin">
                <h4>Admin</h4>
                <ul>
                  {#if $page.props?.auth?.masquerading}
                    <li>
                      <a class="masquerade" href="/revert-masquerade">
                        <Icon data={IconRevert} class="fill-white" />
                        Revert back
                      </a>
                    </li>
                  {/if}

                  <li>
                    <a href="/app/events">
                      <Icon data={IconCalendar} class="fill-white" />
                      Events
                    </a>
                  </li>
                </ul>
              </li>
            {/if}
          </ul>
        </nav>

        <Wallet total={$page.props?.auth?.wallet?.display_total} />
      </div>
    </div>

    <div class="menu-main">
      <Logo theme="light" type="text" href="/app/respond" outerClass="block h-auto w-full max-w-[100px]" />

      <button type="button" class="-m-2.5 p-2.5 text-white lg:hidden" on:click|preventDefault={openSideMenu}>
        <span class="sr-only">Open sidebar</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
             aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
    </div>

    <div class="content">
      <slot />
    </div>
  </main>
</Layout>

<style lang="postcss">
  main {
    @apply h-full;
  }

  .off-canvas {
    @apply relative -z-10 lg:hidden;

    &.open {
      @apply z-40;

      .menu {
        @apply flex z-40 opacity-100;
      }

      .backdrop {
        @apply fixed inset-0 bg-grey-800/80 block opacity-100;
      }
    }

    .backdrop {
      @apply opacity-0 transition-opacity duration-300 ease-in-out;
    }

    .menu {
      @apply fixed opacity-0 -z-10 top-2 left-2 right-2 sm:right-auto md:top-4 md:left-4 flex sm:grow sm:w-full max-w-sm flex-col gap-y-5 overflow-y-auto bg-white px-5 py-4 transition-opacity duration-300 ease-in-out rounded-2xl;

      & > div {
        @apply relative lg:mr-16 flex w-full;
      }
    }

    .close {
      @apply absolute top-0 right-4 z-40 flex w-16 justify-end;

      button {
        @apply -m-2.5 p-2.5;
      }
    }

    .links {
      @apply w-full overflow-y-auto overscroll-y-contain max-h-[calc(100vh-65px)];

      ul ul {
        @apply my-3;
      }

      li a,
      li button {
        @apply flex gap-x-3 w-full rounded-3xl py-1.5 px-4 text-sm leading-6 no-underline items-center transition-colors duration-300;

        &:hover {
          @apply bg-blue-100 text-black;
        }
      }
    }
  }

  .sidebar {
    @apply hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-80 lg:flex-col;

    & > div {
      @apply flex grow flex-col gap-y-5 overflow-y-auto border-r border-grey-200 bg-blue text-white px-6 py-4;
    }
  }

  .header {
    @apply flex justify-center mt-4 mb-2;
  }

  nav {
    @apply flex flex-1 flex-col mb-14;

    h4 {
      @apply text-xs text-blue lg:text-white uppercase font-semibold ml-4 my-2;
    }

    ul, li {
      @apply list-none m-0 p-0;
    }

    ul {
      @apply flex flex-1 flex-col gap-y-1;
    }
  }

  .menu-main {
    @apply sticky top-0 z-30 flex items-center justify-between gap-x-6 bg-blue px-4 py-4 shadow-sm lg:hidden;
  }

  .content {
    @apply lg:pl-80 h-full;
  }

  .copy,
  .plugin,
  .logout,
  .admin li a {
    @apply flex gap-x-3 rounded-3xl py-1.5 px-4 text-sm leading-6 hover:text-white hover:bg-blue no-underline items-center transition-colors duration-300 w-full;

    &:hover {
      @apply bg-white/15 text-white;

      svg {
        @apply fill-black lg:fill-white;
      }
    }

    span {
      @apply ml-auto w-8 min-w-max whitespace-nowrap rounded-full bg-red px-1.5 py-0.5 text-center text-xs leading-5 text-white font-semibold;
    }

    svg {
      @apply fill-black lg:fill-white w-4 h-4;
    }
  }
</style>
