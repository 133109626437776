<script>
  import { inertia, page } from "@inertiajs/svelte";
  import Layout from "@/Layouts/User.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
</script>

<Layout profile={$page.props?.profile}>
  <Seo
    title={`Pitch ${$page.props?.profile?.name}`}
    breadcrumbs={[
      { name: 'Marketplace', slug: '/marketplace' },
      { name: $page.props?.profile?.name },
    ]}
    metaDescription={$page.props?.profile?.about ? $page.props?.profile?.about : `Pitch ${$page.props?.profile?.name}`}
    ogImage={{
      url: `${$page.props?.common.app_url  }/og-images/users/${  $page.props?.profile?.slug  }.png`,
      alt: $page.props?.profile?.name,
    }}
    twitterImage={{
      url: `${$page.props?.common.app_url  }/og-images/users/${  $page.props?.profile?.slug  }.png`,
      alt: $page.props?.profile?.name,
    }}
  />

  <div class="pitch">
    <a class="btn-red" href={`/@${$page.props?.profile.slug}/pitch`} use:inertia={{ preserveScroll: true }}>
      Pitch Me
    </a>

    <div class="ellipsis"></div>

    {#if !$page.props?.profile?.avg_offer || $page.props?.profile?.avg_offer < $page.props?.profile?.pitch_price}
      <p>My Minimum Offer: <strong>${$page.props?.profile?.pitch_price ?? 25}</strong></p>
    {:else}
      <p>My Average Offer: <strong>${$page.props?.profile?.avg_offer}</strong></p>
    {/if}

    <div class="ellipsis"></div>
    <img src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
  </div>
</Layout>

<style lang="postcss">
  .pitch {
    @apply bg-blue-800 py-5 px-6 lg:px-10 flex flex-col sm:flex-row gap-4 sm:gap-0 items-center justify-between text-white;
  }

  .ellipsis {
    background-image: url(/assets/dash.svg);
    @apply hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mx-4;
  }

  p {
    @apply m-0;
  }

  img {
    @apply hidden sm:block w-12 rounded-lg
  }
</style>
