<script>
  import Layout from "@/Layouts/Page.svelte"
  import Card from "@/Components/Layout/Card.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {page, useForm} from "@inertiajs/svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import Alert from "@/Components/Elements/Alert.svelte";

  const form = useForm({
    id: $page.props?.id,
    u: $page.props?.u,
    type: $page.props?.type,
  }),

   handleSubmit = (event) => {
    event.preventDefault();
    $form.post('/unsubscribe');
  }
</script>

<Seo title="Unsubscribe" />

<Layout nav footer>
  <main>
    <Card title="Unsubscribe" class="max-w-3xl mx-auto text-center">
      {#if $page.props?.success}
        <Alert type="success">
          <p class="m-0">You have successfully unsubscribed from <a href="/org/{$page.props?.seller?.data?.org_slug}">{$page.props?.organisation?.name}</a>.</p>
        </Alert>
      {:else if $page.props?.error}
        <p class="text-red-500">{$page.props.error}</p>
      {:else}
        <p>You have received a pitch from <a href="/@{$page.props?.seller?.data?.slug}">{$page.props?.seller?.data?.name}</a> at <a href="/org/{$page.props?.seller?.data?.org_slug}">{$page.props?.organisation?.name}</a></p>
        <p>Would you like to stop receiving pitches from this organisation?</p>

        <form action="/unsubscribe" method="post" class="mb-12" on:submit={(event) => handleSubmit(event)}>
          <input type="hidden" name="id" value={$page.props?.id} />
          <input type="hidden" name="u" value={$page.props?.u} />
          <input type="hidden" name="type" value={$page.props?.type} />

          <div class="flex justify-center gap-4">
            <Button type="submit" size="sm" color="red" on:click={(event) => handleSubmit(event)}>Yes</Button>
            <a href="https://www.pitchfire.com" size="sm" class="btn-green">No</a>
          </div>
        </form>

        <p>Navigate to the home page to login or search our buyer marketplace.</p>

        {#if $page.props?.auth.loggedIn}
          <Button href="/app">Go to home page</Button>
          <Button href="/app/marketplace">Go to marketplace</Button>
        {:else}
          <Button href="/">Go to home page</Button>
          <Button href="/marketplace">Go to marketplace</Button>
        {/if}
      {/if}
    </Card>
  </main>
</Layout>

<style lang="postcss">
  main {
    @apply mx-auto px-4 py-8 container h-full max-w-7xl;
  }
</style>
