<script>
  import Layout from "@/Layouts/Page.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import Loader from "@/Components/Elements/Loader.svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import InfiniteScroll from "@/Components/Layout/InfiniteScroll.svelte";
  import SelectBox from "@/Components/Forms/SelectBox.svelte";
  import { onMount } from "svelte";
  import classNames from "classnames";
  import { persisted } from "svelte-persisted-store";
  import {inertia, page, useForm} from "@inertiajs/svelte";
  import Input from "@/Components/Forms/Input.svelte";
  import Icon from "@/Components/Assets/Icon.svelte";
  import { IconSearch } from "@/Components/Assets/Icons/index.js";
  import Alert from "@/Components/Elements/Alert.svelte";

  let results = $page.props?.results?.data;

  const managementStore = persisted("marketplace_management", []),
   departmentsStore = persisted("marketplace_departments", []),
   revenueStore = persisted("marketplace_revenues", []),
   employeesStore = persisted("marketplace_employees", []),
   industriesStore = persisted("marketplace_industries", []);

  let filtersActive = false,
   currentPage = 1,
   currentTotal = $page.props?.results?.meta?.total,
   height,
   loading = true,

   form = useForm({
    search: '',
    management: [],
    departments: [],
    revenue: [],
    employees: [],
    industries: [],
    page: currentPage,
  });

  const prePopulate = (urlKey, storageKey, items) => {
    const url = new URL($page.props.common.app_url + $page.url),
     params = new URLSearchParams(url.search);

    if (params.has(urlKey)) {
      const split = params.get(urlKey).split(',');
      return items?.filter((a) => split.includes(a.slug));
    }
      const storedStr = typeof window !== 'undefined' ? localStorage.getItem(storageKey) : '',
       stored = storedStr ? JSON.parse(storedStr) : [];
      return items?.filter((a) => stored.includes(a.value));

  },

   formatNumber = (number) => number > 0 ? new Intl.NumberFormat().format(number) : 0,

   handleSubmit = (event, more = false) => {
    event?.preventDefault();
    loading = !more;
    $form.page = more ? currentPage : 1;

    $form
      .transform((data) => {
        data.management = data.management ? data.management?.map((x) => x.value) : [];
        data.departments = data.departments ? data.departments?.map((x) => x.value) : [];
        data.revenue = data.revenue ? data.revenue?.map((x) => x.value) : [];
        data.employees = data.employees ? data.employees?.map((x) => x.value) : [];
        data.industries = data.industries ? data.industries?.map((x) => x.value) : [];
        return data;
      })
      .post('/marketplace', {
        preserveScroll: true,
        only: ['results'],
        onSuccess: (response) => {
          loading = false;
          const data = response?.props?.results?.data;
          results = more ? [ ...results, ...data ] : data;
          currentTotal = formatNumber(response?.props?.results?.meta?.total);
        },
        onFinish: () => {
          loading = false;
        },
        onCancel: () => {
          loading = false;
        }
      });
  };

  onMount(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    $form.search = urlParams.get("s") || "";

    $form.management = prePopulate('management', 'marketplace_management', $form.management)
    $form.departments = prePopulate('departments', 'marketplace_departments', $form.departments)
    $form.industries = prePopulate('industries', 'marketplace_industries', $form.industries)
    $form.revenue = prePopulate('revenue', 'marketplace_revenues', $form.revenue)
    $form.employees = prePopulate('employees', 'marketplace_employees', $form.employees)

    handleSubmit();
  });

  /** @param {boolean} val */
  const toggleFilters = (val) => {
    filtersActive = val;
  },

   resetForm = (event) => {
    loading = true;
    $form.cancel();

    managementStore.set([]);
    departmentsStore.set([]);
    revenueStore.set([]);
    employeesStore.set([]);
    industriesStore.set([]);

    currentPage = 1;
    $form.reset();
    $form.clearErrors();
    handleSubmit(event);
  },

   searchInput = (event) => {
    event.preventDefault();
    handleSubmit(event);
  };
</script>

<Seo
  title="Marketplace"
  breadcrumbs={[
    { name: 'Home', slug: '/' }
  ]}
/>

<Layout nav footer>
  <div class="wrap page">
    <section>
      <header class="results-head">
        <h1>Marketplace</h1>
        {#if currentTotal && $page.props?.auth?.loggedIn}<span>{currentTotal} results</span>{/if}
      </header>

      <form method="post" action="/marketplace/search" on:submit={(event) => searchInput(event)}>
        <Input
          id="search"
          type="text"
          name="search"
          wrapperClass="md:!w-3/4 mb-6 border border-grey-200 rounded-lg"
          inputClass="!border-0"
          class="relative mb-4 xl:!w-2/3 flex"
          placeholder="Search for a buyer, company or job title"
          size="sm"
          autofocus
          minlength="3"
          slot="right"
          error={$form.errors.search}
          disabled={loading || form.processing}
          bind:value={$form.search}
        >
          <button type="submit" disabled={loading || form.processing} on:click={(event) => searchInput(event)} slot="right" class="search-btn">
            <Icon data={IconSearch} size="sm" />
          </button>
        </Input>
      </form>

      {#if loading}
        <Loader />
      {:else if results?.length > 0}
        <div class="results" style:--results-height="{height}px">
          {#each results as u}
            <Profile
              title={u.name}
              image={u.avatar}
              imageAlt={u.name}
              theme="minimal"
              size="xl"
            >
              <div class="title">{u.job_title ?? u.job_title ?? u.job_level ?? ''}</div>
              {#if u.source === 'db'}
                <a class="org" href={u.org_url}><strong>{u.org_name}</strong></a>
              {:else}
                <div class="org"><strong>{u.org_name}</strong></div>
              {/if}

              {#if $page.props?.auth?.user?.role === "Admin" && u.source === 'db'}
                <a
                  href="/masquerade/{u.id}"
                  use:inertia
                  class="block mt-4 w-auto text-xs">Login as</a
                >
              {/if}

              <div slot="actions">
                {#if $page.props?.auth?.user?.role === "Admin"}
                  <div class="mb-2">Status: {u?.status}</div>
                {/if}

                {#if u?.deleted_at}
                  <Alert type="error" message="Profile Deleted" />
                {:else}
                  <Button size="sm" href={u.url}>Pitch</Button>
                {/if}
              </div>
            </Profile>
          {/each}

          <InfiniteScroll
            hasMore={results?.length !== currentTotal}
            threshold={100}
            on:loadMore={(event) => {
              currentPage++;
              handleSubmit(event, true);
            }}
          />
        </div>
      {:else}
        <div class="empty">
          <p>Sorry, we were unable to find any results for your selection</p>
        </div>
      {/if}
    </section>

    <aside>
      <header class="filter-head">
        <div>
          <span>Filters</span>
          <span>
            (<button on:click={() => toggleFilters(true)}>show</button> /
            <button on:click={() => toggleFilters(false)}>hide</button>)
          </span>
        </div>
      </header>

      <form
        bind:clientHeight={height}
        class={classNames("filters", filtersActive && "open")}
        method="post"
      >
        <div>
          <h2>I want to pitch people</h2>

          <div>
            <label for="level">who are the <strong>management</strong> level of</label>
            <SelectBox
              bind:value={$form.management}
              id="level"
              name="level"
              disabled={loading || form.processing}
              options={$page.props?.tags?.management_level}
              placeholder="Select Management Levels"
            />
          </div>

          <div>
            <label for="department">who work in the <strong>department</strong> of</label>
            <SelectBox
              bind:value={$form.departments}
              id="departments"
              name="departments"
              disabled={loading || form.processing}
              options={$page.props?.tags?.department}
              placeholder="Select Department"
            />
          </div>

          <h2>Who work at companies</h2>
          <div>
            <label for="industries">in the following <strong>industries</strong></label>
            <SelectBox
              bind:value={$form.industries}
              id="industries"
              name="industries"
              disabled={loading || form.processing}
              options={$page.props?.tags?.industry}
              placeholder="Select Industry"
            />
          </div>

          <div>
            <label for="revenues">with <strong>revenue ranges</strong> of</label>
            <SelectBox
              bind:value={$form.revenue}
              id="revenues"
              name="revenues"
              disabled={loading || form.processing}
              options={$page.props?.tags?.revenue_range}
              placeholder="Select Revenue"
            />
          </div>

          <div>
            <label for="employees">and <strong>employee ranges</strong> of</label>
            <SelectBox
              bind:value={$form.employees}
              id="employees"
              name="employees"
              disabled={loading || form.processing}
              options={$page.props?.tags?.employees_range}
              placeholder="Select Employee Range"
            />
          </div>

          <div class="actions">
            <Button size="xs" color="secondary" type="submit" disabled={loading} loading={form.processing} on:click={(event) => handleSubmit(event)}>Filter</Button>
            <Button size="xxs" type="button" on:click={(event) => resetForm(event)}>Reset</Button>
          </div>
        </div>
      </form>
    </aside>
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    aside {
      @apply order-1 md:col-span-1;
    }

    section {
      @apply order-2 md:col-span-2 lg:col-span-3;
    }

    .title,
    .org {
      @apply text-sm;
    }

    .search-btn {
      @apply py-2 px-3 hover:fill-grey-200 focus:fill-grey-200;
    }

    &.page {
      @apply grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 border-2 bg-white border-grey-200 shadow-lg rounded-xl;

      aside {
        @apply py-5 px-4 bg-grey-100 md:border-r-2 border-grey-200 rounded-xl md:rounded-l-xl;
      }

      section {
        @apply px-4 pt-8 pb-5 md:p-8 rounded-r-xl;
      }

      .results {
        @apply max-h-[calc(100vh-300px)] md:max-h-[calc(var(--results-height)-70px)] md:min-h-[560px];
      }
    }
  }

  .filter-head {
    @apply flex justify-between items-center;

    span {
      @apply text-xs lg:text-xs uppercase text-grey-500 font-semibold;

      button {
        @apply text-blue hover:text-red focus:text-red transition-colors;
      }

      &:last-child {
        @apply inline md:hidden;
      }
    }
  }

  .filters {
    @apply h-0 opacity-0 md:w-auto md:h-auto md:opacity-100 md:mt-2;

    h2 {
      @apply text-sm mt-6;
    }

    & > div {
      @apply h-0 md:h-auto overflow-hidden;

      & > div {
        @apply flex flex-col gap-1 my-4;

        &.actions {
          @apply flex-row gap-2 mt-6 justify-between;
        }
      }
    }

    &.open {
      @apply w-auto h-auto transition-opacity opacity-100 mt-2;

      & > div {
        @apply h-auto;
      }
    }

    label {
      @apply text-xs;
    }
  }

  .results-head {
    @apply flex justify-between items-center gap-2 mb-8;

    h1 {
      @apply text-2xl lg:text-3xl;
    }

    span {
      @apply text-base text-blue font-normal;
    }
  }

  .results {
    @apply flex flex-col gap-4 overflow-auto;
  }
</style>
